<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Өөрчлөлт хадгалах</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
          <el-button type="success" @click="onPressSave('productForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header title="Бүтээгдэхүүн засах" />
        <!-- form error template -->
        <div class="form-error-container mb20" id="error-listener" ref="errorListener" v-if="errorChecker.length != 0">
          <header>Уучлаарай дутуу {{errorChecker.length}} зүйл байна</header>
          <ul class="error-list">
            <div v-for="(file, index) in errorChecker" :key="index">
              <li>{{file}}</li>
            </div>
          </ul>
        </div>
        <el-form label-position="top" :model="productForm" :rules="productRules" ref="productForm" @submit.native.prevent="onPressSave('productForm')">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр (Сайт-д харагдах нэр)" prop="name_mon" >
                        <el-input v-model="productForm.name_mon" placeholder="Говь ноолууран цамц" @change="(name) => capitalizeFirstLetter(name, 'mon')"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Англи нэр" prop="name_eng" >
                        <el-input v-model="productForm.name_eng" placeholder="Gobi sweater" @change="(name) => capitalizeFirstLetter(name, 'eng')"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Бүтээгдэхүүний sku (Харилцагч)">
                        <el-input v-model="productForm.supplier_sku" placeholder="Барааны бүртгэлийн нэр"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Бүртгэлийн нэр (Харилцагч)">
                        <el-input v-model="productForm.register_name" placeholder="Барааны бүртгэлийн нэр"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="Монгол тайлбар" prop="description_mon">
                    <vue-editor v-model="productForm.description_mon"></vue-editor>
                  </el-form-item>
                  <el-form-item label="Англи тайлбар" prop="description_eng">
                    <vue-editor v-model="productForm.description_eng"></vue-editor>
                  </el-form-item>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <el-row type="flex">
                    <header style="padding-top: 5px">Категори</header>
                    <el-popover
                      placement="right"
                      title="Категори оруулах заавар"
                      width="300"
                      trigger="click"
                      content=""
                      class="ml10">
                      <p style="word-break: break-word">Харилцагч та өөрийн бараагаа тухайн хамааралтай категорид холбож өгнө үү. 2-5 үндсэн категори сонгох эрхтэй ба хэдэн ч дэд категори сонгож болно.</p>
                      <el-image :src="instructionsImg" alt="Instructions image" />
                      <el-button type="text" size="small" slot="reference"><i class="el-icon-back mr10"></i>Заавар</el-button>
                    </el-popover>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Категори" prop="category_id" multiple class="position-relative">
                        <tree-selector v-model="productForm.category_id" :treeData="categories" multiple width="100%" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Media section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /бүтээгдэхүүн/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImages"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'image')"
                    :on-change="(file) => getFile(file, 'image')">
                    <span>1020x1020</span>
                  </el-upload>
                  <loader :active="isUploading" />
                <p class="text-red" v-if="tempImages.length === 0 && productImg">Бүтээгдэхүүний зурагаа оруулна уу</p>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Зураг /сав, баглаа/</header>
                  <el-upload
                    class="mt5"
                    list-type="picture-card"
                    accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                    action=""
                    :file-list="tempImagesPackage"
                    :auto-upload="false"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="(file) => handleRemove(file, 'package')"
                    :on-change="(file) => getFile(file, 'package')">
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <loader :active="isUploadingPackage" />
                </div>
              </div>
              <!-- Price section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Үнэ</header>
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-form-item label="Борлуулах үнэ" prop="price">
                        <el-input disabled v-model="productForm.price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input disabled placeholder="0%" v-model="productForm.brand_fee_percent">
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Тооцоо нийлэх дүн">
                        <el-input disabled placeholder="0₮" :value="productForm.price - productForm.price * productForm.brand_fee_percent / 100" >
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row class="pb20">
                    <el-col :span="12">
                      <el-checkbox v-model="productForm.sale_check">Энэ хямдралтай бүтээгдэхүүн</el-checkbox>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20" v-if="productForm.sale_check">
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан үнэ">
                        <el-input disabled v-model="productForm.discounted_price" type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Шимтгэлийн хувь">
                        <el-input  v-model="productForm.brand_fee_percent" type="number" placeholder="0%" disabled>
                          <template slot="append">%</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Хямдарсан тооцоо нийлэх дүн">
                        <el-input  :value="productForm.discounted_price - productForm.discounted_price * productForm.brand_fee_percent / 100" disabled type="number" placeholder="0₮">
                          <template slot="append">₮</template>
                        </el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="8" class="mt20">
                      <el-checkbox v-if="productForm.sale_check" v-model="isDiscountDate">Хямдралын хугацаа</el-checkbox>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Эхлэх өдөр" v-if="isDiscountDate">
                        <el-date-picker type="datetime" placeholder="Эхлэх огноо" disabled value-format="yyyy-MM-dd HH:mm:ss" v-model="productForm.start_date_sale"/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="Дуусах өдөр" v-if="isDiscountDate">
                        <el-date-picker type="datetime" placeholder="Эхлэх огноо" disabled value-format="yyyy-MM-dd HH:mm:ss" v-model="productForm.end_date_sale"/>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <!-- Variants section -->
              <div class="panel" v-if="productForm.variants.length > 0">
                <div class="panel-item">
                  <header>Бараа сонголт - {{productForm.variants.length}} төрөл</header>
                </div>
                <div class="panel-item">
                    <el-table
                      :data="productForm.variants"
                      @cell-click="onPressItem"
                      style="width: 100%">
                      <el-table-column
                        label="Нэр"
                        :show-overflow-tooltip="true"
                        prop="variant_name">
                      </el-table-column>
                      <el-table-column
                        label="Төлөв">
                        <template slot-scope="scope">
                          <el-tag v-if="scope.row.variant_status === 'price_inactive'" type="warning">Үнэ батлуулаагүй</el-tag>
                          <el-tag v-else-if="scope.row.variant_status === 'brand_inactive'" type="info">Брэнд хаалттай</el-tag>
                          <el-tag v-else-if="scope.row.variant_status === 'inactive'" type="info">Идэвхгүй</el-tag>
                          <el-tag v-else type="primary">Идэвхитэй</el-tag>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Шимтгэлийн хувь">
                        <template>
                          <el-input type="number" disabled v-model="productForm.brand_fee_percent">
                            <template slot="append">%</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Борлуулах үнэ">
                        <template slot-scope="scope">
                          <el-input disabled v-model="scope.row.variant_sellPrice">
                            <template slot="append">₮</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column
                        label="Тооцоо нийлэх дүн">
                        <template slot-scope="scope">
                          <el-input disabled type="number" :value="scope.row.variant_sellPrice - scope.row.variant_sellPrice * productForm.brand_fee_percent / 100">
                            <template slot="append">₮</template>
                          </el-input>
                        </template>
                      </el-table-column>
                      <el-table-column>
                        <template slot-scope="scope">
                          <!-- <el-input disabled type="number" :value="scope.row.variant_sellPrice - scope.row.variant_sellPrice * productForm.brand_fee_percent / 100">
                            <template slot="append">₮</template>
                          </el-input> -->
                          <el-button type="primary" @click="clickSaleButton(scope.row)">Үнэ засах</el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                </div>
              </div>
              <!-- Inventory section -->
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                </div>
                <div class="panel-item">
                  <el-row>
                    <el-col :span="12" class="mb20">
                      <el-tooltip class="item" effect="dark" placement="bottom-start" visible-arrow>
                        <div slot="content">Цахим бараа гэдэг нь биет бус буюу тасалбар, кино, <br/> дуу хөгжим, купон, видео сургалт гэх мэт барааг хэлнэ.<br/> Энэ сонголтыг сонгосон тохиолдолд хүргэлтийн үнэ <br/> 0 болохыг анхаарна уу.</div>
                        <el-switch v-model="productForm.is_physical" active-text="Энэ нь биетэй бүтээгдэхүүн" inactive-text="Цахим бараа"></el-switch>
                      </el-tooltip>
                    </el-col>
                  </el-row>
                </div>
                <div class="panel-item" v-if="productForm.is_physical">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Жин">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Weight" type="number">
                              <el-select v-model="productSize.weightSuffix" slot="append">
                                <el-option label="kg" value="kg"></el-option>
                                <el-option label="gram" value="g"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Урт">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Length" type="number">
                              <el-select v-model="productSize.lengthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                        label="Өргөн">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input  v-model="productSize.Width" type="number">
                              <el-select v-model="productSize.widthSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                            </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        label="Өндөр">
                        <el-row :gutter="5">
                          <el-col>
                            <el-input v-model="productSize.Height" type="number">
                              <el-select v-model="productSize.heightSuffix" slot="append">
                                <el-option label="cm" value="cm"></el-option>
                                <el-option label="inch" value="inch"></el-option>
                              </el-select>
                          </el-input>
                          </el-col>
                        </el-row>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Брэнд</header>
                  <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                    <el-select
                      class="block"
                      v-model="productForm.supplier_id"
                      filterable
                      placeholder="Таван богд"
                      :loading="supplierLoading">
                      <el-option
                        v-for="(item, index) in suggestedSupplier"
                        :key="index"
                        :label="item.supplier_monName"
                        :value="item.supplier_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Брэнд" prop="brand_id">
                    <loader :active="isLoadBrand" />
                    <el-select
                      v-model="productForm.brand_id"
                      @change="checkBrand"
                      filterable
                      class="block">
                      <el-option
                        v-for="(brand, indexBrand) in brandOptions"
                        :key="indexBrand"
                        :label="brand.brand_monName"
                        :value="brand.brand_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Агуулах" prop="warehouse_id">
                    <el-select class="block" v-model="productForm.warehouse_id" multiple disabled>
                      <el-option
                        v-for="(item, indexWarehouse) in warehouses"
                        :key="indexWarehouse"
                        :label="item.name_mon"
                        :value="item.warehouse_id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Эрэмбэ">
                    <el-input v-model="productForm.sort" type="number" placeholder="Эрэмбэ оруулах ..."></el-input>
                  </el-form-item>
                </div>
              </div>
              <!-- Properties section -->
              <div class="panel">
                <div class="panel-item pb20">
                  <el-row type="flex">
                    <header style="padding-top: 5px">Шинж чанар</header>
                    <el-popover
                      placement="left"
                      title="Шинж чанар оруулах заавар"
                      width="350"
                      trigger="click"
                      class="ml10">
                      <el-collapse>
                        <el-collapse-item title="Гоо сайхан" name="1">
                          <ul>
                            <li>
                              <div>Нэр хэсэгт: Арьсны төрөл</div>
                              <div>Утга хэсэгт: Хуурай холимог гэх мэт</div>
                            </li>
                            <li>
                              <div>Нэр хэсэгт: Төрөл</div>
                              <div>Утга хэсэгт: Гарны тос нүүрний тос гэх мэт 3-5 <br /> шинж чанар бичих</div>
                            </li>
                          </ul>
                        </el-collapse-item>
                        <el-collapse-item title="Электрон бараа" name="2">
                          <ul>
                            <li>
                              <div>Нэр хэсэгт: Төрөл</div>
                              <div>Утга хэсэгт: Гар утас, зурагт</div>
                            </li>
                            <li>
                              <div>Нэр хэсэгт: Гал тогооны хэрэгсэл</div>
                              <div>Утга хэсэгт: Хөргөгч, зуух гэх мэт 3-5 <br /> шинж чанар бичих</div>
                            </li>
                          </ul>
                        </el-collapse-item>
                        <el-collapse-item title="Гэр ахуй" name="3">
                          <ul>
                            <li>
                              <div>Нэр хэсэгт: Хүйс</div>
                              <div>Утга хэсэгт: Эрэгтэй, эмэгтэй</div>
                            </li>
                            <li>
                              <div>Нэр хэсэгт: Материал</div>
                              <div>Утга хэсэгт: Даавуу, арьсан гэх мэт 3-5 <br /> шинж чанар бичих</div>
                            </li>
                          </ul>
                        </el-collapse-item>
                      </el-collapse>
                      <el-image src="https://image.toktok.mn/Awrg5QKPcO1648431104167.png"></el-image>
                      <el-button type="text" size="small" slot="reference"><i class="el-icon-back mr10"></i>Заавар</el-button>
                    </el-popover>
                  </el-row>
                  <el-form-item label="Нэр">
                    <el-select
                      v-model="property.name"
                      filterable
                      @change="onChangeProperty"
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesName"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Утга">
                    <el-select
                      v-model="property.value"
                      filterable
                      class="block">
                      <el-option
                        v-for="(item, index) in propertiesValue"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="default" class="block" @click="addProperty">Шинж нэмэх</el-button>
                  </el-form-item>
                </div>
                <div class="panel-item" v-for="(property, indexProperties) in productForm.properties" :key="indexProperties">
                  <div class="property-item">
                    <el-row :gutter="10" type="flex" align="middle">
                      <el-col :span="18">
                        <div>
                          <strong>{{property.name}}</strong>
                        </div>
                        <div>
                          <span class="text-color-secondary">{{property.value}}</span>
                        </div>
                      </el-col>
                      <el-col :span="6" class="text-right">
                        <i class="el-icon-delete cursor-pointer" @click="removeProperty(property)"></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="border-top pt20">
            <el-col :span="12">
              <el-button type="danger" @click="deleteDialogVisible = true">Устгах</el-button>
            </el-col>
            <el-col :span="12">
              <div class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressSave('productForm')">Хадгалах</el-button>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Өөрчлөлтүүдийг хадгалах уу"
      :visible.sync="confirmLeaveForm"
      width="50%">
      <span>Хэрвээ та буцах товч дарвал таны хийсэн бүх өөрчлөлт устагдахыг анхаарна уу.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10">Үргэлжлүүлэх</el-button>
        <el-button type="danger" @click="onPressClose">Буцах</el-button>
      </span>
    </el-dialog>
    <!-- Image view dialog -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
      title="Үнэ өөрчлөх"
      :visible.sync="priceDialog"
      width="20%">
      <el-row v-if="this.variantData !== null">
        <el-input placeholder="Үнэ" v-model="variantData.variant_sellPrice">
          <template slot="append">₮</template>
        </el-input>
        <el-checkbox class="mt10" v-model="isSale">Хямдралтай эсэх</el-checkbox>
        <el-input class="mt10" v-model="variantData.variant_sale_price" v-if="isSale" placeholder="Хямдралтай үнэ">
          <template slot="append">₮</template>
        </el-input>
        <el-checkbox class="mt10" v-if="isSale" v-model="isSaleTime">Хямдралын хугацаа оруулах</el-checkbox>
        <el-date-picker
        v-if="isSaleTime"
        class="mt10"
        style="width: 100%"
        value-format="yyyy-MM-dd HH:mm:ss"
          v-model="isSaleDateTime"
          type="datetimerange"
          align="right"
          start-placeholder="Эхлэх огноо"
          end-placeholder="Дуусах огноо"
          >
        </el-date-picker>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="priceDialog = false">Буцах</el-button>
        <el-button type="success" @click="changePrice" :loading="priceLoading">Хадгалах</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="`${this.productForm.name_mon} - бүтээгдэхүүнийг устгах.`"
      :visible.sync="deleteDialogVisible"
      width="35%">
      <el-form :model="deleteDesc" :rules="rulesDesc" ref="deleteDesc">
        <el-form-item label="Шалтгаан" prop="desc">
          <el-input v-model="deleteDesc.desc" type="textarea" placeholder="Та устгаж буй шалтгаанаа оруулна уу? ..."></el-input>
        </el-form-item>
      </el-form>
      <p style="color: red">Tа устгах товч дарвал тухайн бүтээгдэхүүний бүх хувилбарууд дагаж устгаадахыг анхаарна уу.</p>
      <el-table :data="this.productForm.variants">
        <el-table-column prop="variant_name"></el-table-column>
        <el-table-column prop="variant_sku"></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">Буцах</el-button>
        <el-button type="danger" @click="deleteProduct('deleteDesc')">Устгах</el-button>
      </span>
    </el-dialog>
    <loader :active="isLoading" is-full-screen />
  </div>
</template>
<style scoped>
.description {
  text-align: justify;
  border-radius: 5px;
  padding: 15px;
}
</style>
<script>
import { VueEditor } from 'vue2-editor'
import TreeSelector from '../../../components/common/treeSelector'
import services from '../../../helpers/services'
import { handlers } from '../../../helpers/custom'
import CustomHeader from '../../../components/common/customHeader'
import Loader from '../../../components/common/loader'
import { getSupplierId } from '../../../utils/auth'
function deleteProps (obj, prop) {
  for (const p of prop) {
    (p in obj) && (delete obj[p])
  }
}

const PRODUCT_DATA = {
  sort: '',
  supplier_sku: '',
  category_id: [],
  main_category_id: '',
  start_date_sale: null,
  end_date_sale: null,
  get_sale_price: '',
  name_mon: '',
  name_eng: '',
  register_name: '',
  description_mon: '',
  description_eng: '',
  price: '',
  get_price: '',
  sell_check: false,
  sale_check: false,
  discounted_price: '',
  image: [],
  package_image: [],
  sku: '',
  is_physical: true,
  brand_id: '',
  supplier_id: '',
  barcode: '',
  // status: 'active',
  variants: [],
  properties: [],
  physical_size: {
    Weight: '',
    Length: '',
    Width: '',
    Height: ''
  }
}
export default {
  name: 'addProduct',
  components: {
    CustomHeader,
    TreeSelector,
    Loader,
    VueEditor
  },
  data () {
    return {
      warehouses: [],
      deleteDesc: {
        desc: ''
      },
      deleteDialogVisible: false,
      priceLoading: false,
      isSale: false,
      isSaleDateTime: [],
      isSaleTime: false,
      variantData: null,
      priceDialog: false,
      instructionsImg: 'https://image.toktok.mn/DAT4XbvduS1648197446445.png',
      productImg: false,
      percent: 0,
      isDiscountDate: false,
      propertiesName: [],
      propertiesValue: [],
      property: {
        name: '',
        value: ''
      },
      visibleSuggest: false,
      categoryResult: [],
      searchValue: '',
      defaultProps: {
        parent: 'parent',
        value: 'uniq_id',
        label: 'name_mon',
        children: 'childrens'
      },
      isLoading: false,
      isUploading: false,
      isUploadingPackage: false,
      isLoadBrand: false,
      confirmLeaveForm: false,
      dialogImageUrl: '',
      isNewForm: true,
      dialogVisible: false,
      categories: [],
      tempImages: [],
      tempImagesPackage: [],
      options: [],
      // statusOption: [],
      suggestedSupplier: [],
      supplierLoading: false,
      brandOptions: [],
      productSize: {
        Weight: '',
        Length: '',
        Width: '',
        Height: '',
        weightSuffix: 'kg',
        heightSuffix: 'cm',
        widthSuffix: 'cm',
        lengthSuffix: 'cm'
      },
      backUpProductData: PRODUCT_DATA,
      productForm: PRODUCT_DATA,
      rulesDesc: {
        desc: [
          { required: true, message: 'Та шалтгаанаа заавал оруулна уу...', trigger: 'blur' }
        ]
      },
      productRules: {
        name_mon: [
          { required: true, message: 'Монгол нэрээ оруулна уу', trigger: 'change' }
        ],
        name_eng: [
          { required: true, message: 'Англи нэрээ оруулна уу', trigger: 'change' }
        ],
        description_mon: [
          { required: true, message: 'Монгол тайлбараа оруулна уу', trigger: 'change' }
        ],
        description_eng: [
          { required: true, message: 'Англи тайлбараа оруулна уу', trigger: 'change' }
        ],
        category_id: [
          { required: true, message: 'Категорио сонгоно уу', trigger: 'blur' }
        ],
        price: [
          { required: true, message: 'Үнээ оруулна уу', trigger: 'change' }
        ],
        // get_price: [
        //   { required: true, message: 'Татах үнээ оруулна уу', trigger: 'change' }
        // ],
        status: [
          { required: true, message: 'Төлөвөө сонгоно уу', trigger: 'change' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'change' }
        ],
        brand_id: [
          { required: true, message: 'Брэндээ сонгоно уу', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.product_id) {
      this.getOneProduct(this.$route.params.product_id)
      this.getOptions()
      this.getSuppliers()
      this.getBrand()
    }
  },
  computed: {
    errorChecker () {
      var errors = []
      if (!this.isNewForm) {
        if (this.tempImages.length === 0) {
          errors.push('Зураг оруулна уу')
        }
        if (this.productForm.properties.length === 0) {
          errors.push('Шинж чанар оруулна уу')
        }
      }
      return errors
    },
    categoryId () {
      return this.productForm.category_id
    }
  },
  watch: {
    categoryId (newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue) || newValue.length === 0) {
        this.getPropertiesName(newValue)
      }
    }
  },
  methods: {
    async getWarehousesBySupplierId () {
      this.loading = true
      const body = {
        supplier_id: await getSupplierId(),
        size: 100,
        from: 0
      }
      services.getWarehousesBySupplierId(body).then(response => {
        this.loading = false
        if (response.status === 'success' && response.data) {
          this.warehouses = response.data
        } else {
          this.warehouses = []
        }
      })
    },
    deleteProduct (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.deleteProduct({
            id: this.productForm.productId,
            desc: this.deleteDesc.desc
          }).then(response => {
            if (response.status === 'success') {
              this.alertReporter('Амжилттай', `${this.productForm.name_mon} нэртэй бүтээгдэхүүн устгагдлаа`, 'success')
              this.$router.push({
                name: 'products'
              })
            } else if (response.status === 'error') {
              this.alertReporter('Амжилтгүй', response.message)
            }
          })
        }
      })
    },
    changePrice () {
      this.priceLoading = true
      const tempBody = {}
      const payload = {
        accepted_prices: []
      }
      tempBody.discounted_price = this.isSale === true ? parseInt(this.variantData.variant_sale_price) : 0
      tempBody.get_discounted_price = this.isSale === true ? (parseInt(this.variantData.variant_sale_price) - parseInt(this.variantData.variant_sale_price) * parseInt(this.productForm.brand_fee_percent) / 100).toFixed() : 0
      tempBody.is_discount = this.isSale
      tempBody.brand_id = this.productForm.brand_id ? this.productForm.brand_id : ''
      tempBody.supplier_id = this.productForm.supplier_id ? this.productForm.supplier_id : ''
      tempBody.get_price = (parseInt(this.variantData.variant_sellPrice) - parseInt(this.variantData.variant_sellPrice) * parseInt(this.productForm.brand_fee_percent) / 100).toFixed()
      tempBody.product_id = this.variantData.product_id ? this.variantData.product_id : ''
      tempBody.product_name = this.productForm.name_mon ? this.productForm.name_mon : ''
      tempBody.sell_price = parseInt(this.variantData.variant_sellPrice) ? parseInt(this.variantData.variant_sellPrice) : 0
      tempBody.variant_id = this.variantData.variant_id ? this.variantData.variant_id : ''
      tempBody.brand_fee = parseInt(this.productForm.brand_fee_percent) ? parseInt(this.productForm.brand_fee_percent) : 0
      tempBody.start_date = this.isSaleTime ? this.isSaleDateTime[0] ? this.isSaleDateTime[0] : null : null
      tempBody.end_date = this.isSaleTime ? this.isSaleDateTime[1] ? this.isSaleDateTime[1] : null : null
      payload.accepted_prices.push(tempBody)
      services.acceptedPrices(payload).then((response) => {
        if (response.status === 'success') {
          this.alertReporter('Борлуулах үнэ', response.message, 'success')
          this.priceDialog = false
          this.priceLoading = false
        } else if (response.status === 'error') {
          this.alertReporter('Уучлаарай', response.message, 'warning')
          this.priceLoading = false
        }
      })
    },
    clickSaleButton (data) {
      this.priceDialog = true
      this.variantData = data
      if (data.variant_sellPrice !== data.variant_sale_price) {
        this.isSale = true
      }
    },
    checkBrand (id) {
      this.brandOptions.forEach(brand => {
        if (brand.brand_id === id) {
          if (brand.fee_percent === 0) {
            this.alertReporter('Анхааруулга', 'Тухайн брэнд дээр шимтгэлийн хувь оруулаагүй байгаа тул солих боломжгүй. Админтай холбогдоно уу?', 'warning')
            this.productForm.brand_id = this.backUpProductData.brand_id
          }
        }
      })
    },
    onChangeProperty (value) {
      const query = '?properties_name=' + value
      const tempOptions = []
      services.getPropertiesValue(query).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_value,
              label: element.properties_value
            })
          })
        }
      })
      this.propertiesValue = tempOptions
    },

    getPropertiesName (category) {
      const body = {
        is_category: category.length > 0,
        categories_id: category
      }
      const tempOptions = []
      services.getPropertiesName(body).then(response => {
        if (response.status === 'success' && response.data && response.data.length > 0) {
          response.data.forEach(element => {
            tempOptions.push({
              value: element.properties_name,
              label: element.properties_name
            })
          })
        }
      })
      this.propertiesName = tempOptions
    },

    capitalizeFirstLetter (name, type) {
      const caps = name.charAt(0).toUpperCase() + name.slice(1)
      if (type === 'mon') {
        this.productForm.name_mon = caps
      } else {
        this.productForm.name_eng = caps
      }
    },
    onPressItem (data, column) {
      if (Object.prototype.hasOwnProperty.call(column, 'label')) {
        this.$router.push({ name: 'stockDetail', params: { variant_id: data.variant_id } })
      }
    },
    getOneProduct (productId) {
      this.isLoading = true
      services.getOneProduct(productId).then((response) => {
        if (response.status === 'success' && response.data.length !== 0) {
          const data = response.data[0]
          const categoryId = JSON.parse(JSON.stringify(data.category_id))
          data.category_id = ''
          this.productForm = data
          this.productForm.product_id = productId
          this.backUpProductData = JSON.parse(JSON.stringify(data))
          this.getWarehousesBySupplierId()
          if (Object.prototype.hasOwnProperty.call(data, 'physical_size')) {
            this.productSize = {
              Weight: parseFloat(data.physical_size.Weight),
              Length: parseFloat(data.physical_size.Length),
              Width: parseFloat(data.physical_size.Width),
              Height: parseFloat(data.physical_size.Height),
              weightSuffix: data.physical_size.Weight.split(' ')[1],
              heightSuffix: data.physical_size.Height.split(' ')[1],
              widthSuffix: data.physical_size.Width.split(' ')[1],
              lengthSuffix: data.physical_size.Length.split(' ')[1]
            }
          } else {
            data.physical_size = {}
          }
          if (this.productForm.start_date_sale !== null) {
            this.isDiscountDate = true
          } else {
            this.isDiscountDate = false
          }
          this.getCategory(categoryId)
          this.setImages(data.image)
          this.setPackageImages(data.package_image)
          this.isLoading = false
        } else {
          this.onPressClose()
        }
      })
    },
    setImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImages.push({
            url: element
          })
        })
      }
    },

    setPackageImages (image) {
      if (image) {
        image.forEach(element => {
          this.tempImagesPackage.push({
            url: element
          })
        })
      }
    },

    handleRemove (file, type, fileList) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else if (type === 'package') {
        this.tempImagesPackage.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImagesPackage.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    getFile (file, type, fileList) {
      handlers.checkImageSize(file, type === 'image', 1020, 1020).then(() => {
        if (type === 'image') {
          this.isUploading = true
        } else if (type === 'package') {
          this.isUploadingPackage = true
        }
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            if (type === 'image') {
              this.isUploading = false
            } else if (type === 'package') {
              this.isUploadingPackage = false
            }
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              } else if (type === 'package') {
                this.tempImagesPackage.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },
    getCategory (categoryId) {
      const query = '?tree=true'
      services.getCategoriesAsTree(query).then(data => {
        data.forEach(element => {
          element.label = element.name_mon
          element.id = element.uniq_id
          if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
            element.childrens.forEach(el => {
              el.label = el.name_mon
              el.id = el.uniq_id
              if (Object.prototype.hasOwnProperty.call(el, 'childrens')) {
                el.childrens.forEach(el1 => {
                  el1.label = el1.name_mon
                  el1.id = el1.uniq_id
                  if (Object.prototype.hasOwnProperty.call(el1, 'childrens')) {
                    el1.childrens.forEach(el2 => {
                      el2.label = el2.name_mon
                      el2.id = el2.uniq_id
                    })
                    el1.children = el1.childrens
                  }
                })
                el.children = el.childrens
              }
            })
            element.children = element.childrens
          }
        })
        this.categories = data
        this.productForm.category_id = categoryId
      })
    },
    getStatusOptions () {
      this.statusOption = [{
        value: 'active',
        label: 'Идэвхитэй'
      }, {
        value: 'inactive',
        label: 'Идэвхигүй'
      }]
    },
    getOptions () {
      this.options = [{
        value: 'Хэмжээ',
        label: 'Хэмжээ'
      }, {
        value: 'Өнгө',
        label: 'Өнгө'
      }, {
        value: 'Материал',
        label: 'Материал'
      }]
    },
    getBrand () {
      const query = '?size=1000' + '&from=0'
      services.getBrand(query).then(response => {
        if (response.status === 'success' && response.data) {
          this.brandOptions = response.data
        }
      })
    },
    getSuppliers () {
      const query = ''
      services.getSuppliers(query).then(response => {
        this.suggestedSupplier = response.data
      })
    },
    getBrands (data) {
      const tempData = JSON.parse(JSON.stringify(data))
      tempData.forEach(supplier => {
        this.brandOptions = supplier.brands
        this.productForm.supplier_id = supplier.supplier_id
      })
    },
    filterSupplier (val) {
      if (val.length > 1) {
        services.searchSupplier(val).then(response => {
          if (response.datas && Array.isArray(response.datas)) {
            this.suggestedSupplier = response.datas
          } else {
            this.suggestedSupplier = []
          }
        })
      }
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    addSuffix (data) {
      var subData = {}
      subData.Weight = `${data.Weight} ${data.weightSuffix}`
      subData.Height = `${data.Height} ${data.heightSuffix}`
      subData.Width = `${data.Width} ${data.widthSuffix}`
      subData.Length = `${data.Length} ${data.lengthSuffix}`
      return subData
    },
    onPressSave (formName) {
      this.isNewForm = false
      this.productForm.sku = this.productForm.sku === '' ? this.skuGenerator : this.productForm.sku
      this.productForm.physical_size = this.addSuffix(this.productSize)
      this.productForm.price = parseFloat(this.productForm.price)
      this.productForm.get_price = this.productForm.price - this.productForm.price * this.productForm.brand_fee_percent / 100
      this.productForm.get_sale_price = this.productForm.discounted_price - this.productForm.discounted_price * this.productForm.brand_fee_percent / 100
      this.productForm.discounted_price = parseFloat(this.productForm.discounted_price)
      if (this.productForm.sale_check === false) {
        this.productForm.discounted_price = 0
        this.productForm.get_sale_price = 0
        this.productForm.start_date_sale = null
        this.productForm.end_date_sale = null
      }
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.productForm.image = tempImages
      var tempImagesPackage = []
      this.tempImagesPackage.forEach(element => {
        tempImagesPackage.push(element.url)
      })
      this.productForm.package_image = tempImagesPackage
      deleteProps(this.productForm, ['brandName', 'brand_fee_percent', 'inventoryCount', 'inventoryBalance'])
      this.$refs[formName].validate((valid) => {
        if (valid && this.errorChecker.length === 0 && !this.isUploading) {
          var tempData = {
            productId: this.productForm.productId,
            product: this.productForm
          }
          this.isLoading = true
          services.updateProduct(this.productForm.productId, tempData).then(data => {
            this.isLoading = false
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$router.go(-1)
            }
          })
        } else {
          window.scrollTo(0, 0)
          this.productImg = true
          return false
        }
      })
    },
    removeProperty (property) {
      this.productForm.properties = this.productForm.properties.filter(item => property !== item)
    },
    addProperty () {
      if (this.property.name && this.property.value) {
        this.productForm.properties = [...this.productForm.properties, { name: this.property.name, value: this.property.value }]
        this.property.name = ''
        this.property.value = ''
      }
    },
    onPressClose () {
      this.confirmLeaveForm = false
      this.$router.go(-1)
    }
  }
}
</script>
